import React, { useState } from 'react';
import './App.scss';
import catFacts from '../data/catFacts.json';
import dogFacts from '../data/dogFacts.json';

const getRandomFact = (facts, activeFact) => {
  // if (activeFact) {
  //   return activeFact
  // }
  return facts[Math.floor(Math.random() * facts.length)];
};

const startingFact = getRandomFact(catFacts);

function App() {
  const [animal, setAnimal] = useState('Cat');
  const [activeFacts, setActiveFacts] = useState(catFacts);
  const [fact, setFact] = useState(startingFact);
  const [hideFact, setHidden] = useState(false);

  const toggleAnimal = () => {
    if (animal === 'Cat') {
      setAnimal('Dog');
      setActiveFacts(dogFacts);
      setFact(getRandomFact(dogFacts));
    } else {
      setAnimal('Cat');
      setActiveFacts(catFacts);
      setFact(getRandomFact(catFacts));
    }
  };

  return (
    <div className={`body ${animal === 'Cat' ? '' : 'dog-theme'}`}>
      <div className="container">
        <div className="row fact-box">
          <div className="col s12">
            <h2 onClick={toggleAnimal} className="title">
              {animal} Facts!
            </h2>
            <p className={`fact-text ${hideFact ? 'no-show' : ''}`}>{fact}</p>
          </div>
        </div>
        <div className="row">
          <div className="col s4">
            <button
              className="btn button-large red-lighten-2"
              onClick={() => {
                setHidden(true);
                window.setTimeout(() => {
                  setFact(getRandomFact(activeFacts));
                  setHidden(false);
                }, 600);
              }}
            >
              Another
            </button>
          </div>
        </div>
      </div>
      <div className="foot right-align">
        <p>Created by William Bruntrager</p>
      </div>
    </div>
  );
}

export default App;
